// /* Fonts */

// //PT_Sans
// @font-face {
//   font-family: 'ubuntu-light';
//   src: url('../../fonts/Ubuntu/Ubuntu-Light.eot'); /* IE9 Compat Modes */
//   src: url('../../fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Light.woff') format('woff'), /* Pretty Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: 'ubuntu-regular';
//   src: url('../../fonts/Ubuntu/Ubuntu-Regular.eot'); /* IE9 Compat Modes */
//   src: url('../../fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Regular.woff') format('woff'), /* Pretty Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: 'ubuntu-medium';
//   src: url('../../fonts/Ubuntu/Ubuntu-Medium.eot'); /* IE9 Compat Modes */
//   src: url('../../fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'), /* Pretty Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
// }
// @font-face {
//   font-family: 'ubuntu-bold';
//   src: url('../../fonts/Ubuntu/Ubuntu-Bold.eot'); /* IE9 Compat Modes */
//   src: url('../../fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'), /* Pretty Modern Browsers */
//        url('../../fonts/Ubuntu/Ubuntu-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
// }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');